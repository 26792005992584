import React from "react"
import { Link } from "gatsby"
import "./Layout.css"

const Footer = props => {
  return (
    <div className="footer">
      <Link to="/imprint" className="footerText">
        Imprint
      </Link>
      <Link to="/privacy_policy" className="footerText">
        Data protection
      </Link>
      <Link to="/" className="footerText">
        ©KIZO 2020 - all rights reserved
      </Link>
    </div>
  )
}

export default Footer
