import React, { createRef, Fragment } from "react"
import { Link } from "gatsby"
import "./Layout.css"
import Logo from "./../../images/Kizofinal.png"

const Header = ({ productPage, imprint }) => {
  const checkboxRef = createRef()
  return (
    <Fragment>
      <div className="mobileHeader">
        <Link to="/">
          <img src={Logo} alt="mobile Logo" className="mobileLogo" />
        </Link>
        <input
          type="checkbox"
          ref={checkboxRef}
          className="navCheckbox"
          id="navi-toggle"
        />

        <label htmlFor="navi-toggle" className="mobileNavButton">
          <span className="mobileNavIcon">&nbsp;</span>
        </label>
        <div className="mobileNavBackground">&nbsp;</div>
        <nav className="mobileNav">
          <ul className="mobileNavList">
            <li className="mobileNavItem">
              <Link
                to="/#what"
                className="mobileNavLink"
                onClick={() => (checkboxRef.current.checked = false)}
              >
                What we build
              </Link>
            </li>
            <li className="mobileNavItem">
              <Link
                to="/#how"
                className="mobileNavLink"
                onClick={() => (checkboxRef.current.checked = false)}
              >
                How we build
              </Link>
            </li>
            <li className="mobileNavItem">
              <Link
                to="/#why"
                className="mobileNavLink"
                onClick={() => (checkboxRef.current.checked = false)}
              >
                Why we build
              </Link>
            </li>
            <li className="mobileNavItem">
              <Link
                to="/#buildWithUs"
                className="mobileNavLink"
                onClick={() => (checkboxRef.current.checked = false)}
              >
                Build with us
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <header className={`header ${productPage ? "whiteHeader" : null}`}>
        <Link to="/">
          <img className="headerLogo" src={Logo} alt="logo" />
        </Link>
        <nav
          className="headerNav"
          style={imprint ? { color: "white", borderColor: "white" } : {}}
        >
          <Link to="/#what" className="menuItem">
            What we build
          </Link>
          <Link to="/#how" className="menuItem">
            How we build
          </Link>
          <Link to="/#why" className="menuItem">
            Why we build
          </Link>
          <Link to="/#buildWithUs" className="menuItem">
            Build with us
          </Link>
        </nav>
      </header>
    </Fragment>
  )
}

export default Header
